.footerWrapper {
	// height: 50vh;
	width: 100vw;
    overflow: hidden;
	* {
		font-family: 'Poppins', serif;
	}
	// padding: 1rem;
	.message {
		width: calc(100% - 2rem);
		background-color: #212d45;
		padding: 1rem;
		color: white;
		text-align: center;
	}
	.footer {
		width: calc(100% - 6rem);
		padding: 1rem 3rem;
		background-color: #151b28;
		height: 40vh;
		.header {
			display: flex;
			justify-content: space-around;
			height: 80%;
			align-items: center;
			.leftSide {
				display: flex;
				flex-direction: column;
				gap: 0.8rem;
				width: 50%;
				span {
					width: 50%;
					color: white;
					font-size: 0.9rem;
				}
				img {
					width: 20%;
				}
			}
			.rigthSide {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				gap: 2rem;
				// width: 50%;
				span {
					color: white;
					cursor: pointer;
				}
			}
		}
		.copyrigth {
			display: flex;
			justify-content: space-between;
            span{
				font-size: 1rem;
                color: rgba(119, 119, 119, 0.712);
            }
		}
	}
}

@media (max-width: 800px) {
	.footerWrapper {
		.message {
			font-size: 0.8rem;
		}
		.footer {
			width: calc(100% - 2rem);
			padding: 0.5rem 1rem;
			background-color: #151b28;
			height: unset;
			.header {
				height: unset;
				align-items: center;
				width: 100%;
				flex-direction: column;
				font-size: 0.8rem;
				gap: 2rem;
				.leftSide {
					display: flex;
					flex-direction: column;
					width: 100%;
					span {
						width: 100%;
						color: white;
					}
					img {
						display: flex;
						align-self: center;
						width: 40%;
					}
				}
				.rigthSide {
					display: flex;
					flex-direction: row;
					justify-content: flex-end;
					gap: 2rem;
					width: 100%;
					span {
						color: white;
						cursor: pointer;
					}
				}
			}
			.copyrigth {
				display: flex;
				flex-direction: column;
				align-items: center;
				gap: 1rem;
				span{
					text-align: center;
					font-size: 0.7rem;
				}
			}
		}
	}
}
