.wpp{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    width: 40px;
    height: 40px;
    cursor: pointer;
    animation: bounce2 2s ease infinite;
    z-index: 99;
    img{
        width: 100%;
        height: 100%;
    }
}

.wpp:hover{
    transform: scale(1.2);
}

  @keyframes bounce2 {
      0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
      40% {transform: translateY(-15px);}
      60% {transform: translateY(-5px);}
  }