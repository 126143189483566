.app {
    width: 100vw;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0!important;
    padding: 0 !important;
  }
  // *::-webkit-scrollbar {
  //   width: 4px;              
  // }
    
  // *::-webkit-scrollbar-thumb {
  //   background-color: var(--gray-500);   
  //   border-radius: 20px;  
  //   color: var(--gray-100);
  // }
