.findUs {
	* {
		font-family: 'Poppins', serif;
	}
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 100%;
	.iframe {
		width: 50%;
		height: 70%;
		border: none;
		border-radius: 10px;
	}
	.whereWeAre {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		.bodyWrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			background-color: white;
			border-radius: 1rem;
			box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
			padding: 0.8rem;
			margin-left: -6rem;

			gap: 1rem;
			.wrapper {
				display: flex;
				gap: 1rem;
				font-size: 1.1rem;
				// justify-content: center;
				align-items: center;
				svg {
					width: 30px !important;
					height: 30px !important;
				}
			}
		}
	}
}

@media (max-width: 800px) {
	.findUs {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: unset;
		width: 100%;
		margin-top: 1rem;
        .iframe {
            width: 80%;
            height: 70%;
            border: none;
            border-radius: 10px;
        }
		.whereWeAre {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.bodyWrapper {
				margin-left: unset;
				width: 80%;
				.wrapper {
					display: flex;
					gap: 0.4rem;
					font-size: 0.7rem;
					max-width: 100%;
					// justify-content: center;
					align-items: center;
					svg {
						width: 20px !important;
						height: 20px !important;
					}
				}
			}
		}
	}
}
