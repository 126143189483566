.navBar {
	// display: flex;
	width: 100%;
	*{
		img{
			width: 70%;
			height: 30%;
		}
	}
	> div {
		padding: 1rem 3rem;
		gap: 3rem;
		// align-items: flex-start;
		justify-content: space-between; // Alinha os itens à esquerda
		box-shadow: none !important;
	}
}
@media (max-width: 800px) {
	.navBar {
		> div {
			padding: 1rem;
			justify-content: space-between;
		}
		*{
			img{
				width: 70%;
				height: 30%;
			}
		}
	}
}
