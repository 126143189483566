.imageView {
	display: flex;
	width: 80%;
	justify-self: center;
	justify-content: center;
	align-items: center;
	// padding: 1rem ;
	gap: 1rem;
	padding: 1rem;
	.internalImageView {
		display: flex;
		flex-direction: column;
		height: fit-content;
		align-items: center;
		width: 20%;
		background-color: #0053b8;
		padding: 1rem;
		gap: 1rem;
		border-radius: 1rem;
		.footer {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 80%;
			height: 30%;
			gap: 1rem;
			span{
				font-weight: 600;
				color: white;
				font-family: 'Poppins', serif;
			}
		}
		img {
			height: fit-content;
			height: 300px;
			width: 100%;
			object-fit: cover;
		}
	}

}
@media (max-width: 800px) {
	.imageView {
		width: 100%;
		height: unset;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding: 1rem 0px;
		.internalImageView{
			width: 80%;
			padding: 1rem;
		}
	}
}
